import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'flagImageUrl'})
export class FlagImageUrlPipe implements PipeTransform {
  transform(mcc:number, locationLookup:any): string {
    let locationType = 'flags'; // for countries
    let fileName=undefined;
    for (let locationKey in locationLookup) {
      let location = locationLookup[locationKey];
      switch (location['type'])
      {
        case 'country':
          if ('mcc' in location && location['mcc'] == mcc)
          {
            fileName = location['iso'] ? location['iso'] : locationKey;
            break;
          } else continue;

        default: continue;
      }
    }
    
    if (fileName === undefined) return null;
    return 'https://storage.googleapis.com/grn-static/images/'+locationType+'/'+fileName+'.png';
  }
}
