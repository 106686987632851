import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';

import { AngularFireDatabaseModule } from '@angular/fire/database';

import { LeaderboardComponent } from './leaderboard.component';
import { FlagImageUrlPipe } from './flag-image-url.pipe';

@NgModule({
  declarations: [
    LeaderboardComponent,
    FlagImageUrlPipe
  ],
  imports: [
    CommonModule,
    AngularFireDatabaseModule,
    MatTabsModule
  ],
  entryComponents: []
})
export class LeaderboardModule { }
