import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Leaderboards } from './leaderboard.models';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  public selectedGroup: string;
  public selectedSubgroup: string;
  public singleLeaderboard: boolean;
  public leaderboards : Observable<any>;
  public locationLookup: Observable<any>;

  constructor(public db: AngularFireDatabase, public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.locationLookup = this.db.list('/languageGuessingGame/locations').valueChanges();

    this.leaderboards = this.route.paramMap.pipe(
      switchMap(params => {
        if (params.has('group') && params.has('subgroup'))
        {
          this.singleLeaderboard = true;
          this.selectedGroup = params.get('group');
          this.selectedSubgroup = params.get('subgroup');
          return this.db.object('/languageGuessingGame/leaderboards/'+this.selectedGroup+'/'+this.selectedSubgroup).valueChanges();
        } else {
          this.singleLeaderboard = false;
          return this.db.object('/languageGuessingGame/leaderboards').valueChanges();
        }
      })
    );
  }

  subgroupName(subgroupSlug: string, groupSlug: string): string {
    if (groupSlug == 'location') {
      this.locationLookup.subscribe((lookup) => lookup[subgroupSlug] ? lookup[subgroupSlug]['name'] : subgroupSlug);
    } else {
      return subgroupSlug.replace(/([A-Z])([A-Z]+)?|([0-9])([0-9]+)?/g, ' $1$2$3$4').trim();
    }
  }
}
